/* Navbar Section  */
.navbar {
  position: relative !important;
  background-color: white;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  font-size: 1.2rem !important;
 
}

.navbar.fixed {
  position: fixed !important;
  width: 100vw;
  left: 0;
  top: 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: black !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  border: none !important;
  outline: 0 !important;
  box-shadow: 0 0 0 transparent !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    font-size: 1.4rem !important;
  }

  .navbar-nav .nav-item::after {
    display: none !important;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.navbar-brand ion-icon {
  font-size: 25px;
}

.logo {
  font-size: 25px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.navbar-link {
  display: flex !important;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 10px !important;
  padding: 0.8rem 0.5rem 0.2rem !important;
}

.nav-link-label {
  color: black;
  font-size: 15px !important;
  font-weight: 600;
 
}
.nav-link-label:hover {
  color: #150f35!important;
}


.nav-icon {
  width: 30px;
  height: 30px;
  padding-bottom: 5px;
}

.cart {
  position: relative !important;
  z-index: 3;
}

.cart::before {
  content: attr(data-num);
  position: absolute;
  right: 0;
  top: -5px;
  background-color: #0f3460;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  color: white;
  z-index: 5;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}
@media (max-width: 1200px) {
  .nav-link-label {
    font-size: 13px !important;
   
  }
  /* .navbar-nav .nav-item {
    margin: 0 0rem!important;
} */
}

.navbar-nav .nav-item {
  position: relative;
  padding-bottom: 3px !important;
  margin: 0 1rem;
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  z-index: 1;
}

.offcanvas.offcanvas-end {
  width: 300px !important;
}

.media-cart {
  display: none;
}

@media (max-width:767px) {
  .navbar-toggler span {
    width: 25px !important;
    height: 3px !important;
  }
  ion-icon {
    font-size: 20px;
  }

  .logo {
    font-size: 20px;
    color: #0F1035!important;
   
  }
    .navbar-link:hover {
      color: #ffffff;
      /* Màu khi di chuột qua */
    }

  .expanded-cart {
    display: none !important;
  }

  .media-cart {
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }
  
}
.cart::before {
  background-color: red;
}
.icon-text{
  color: #0F1035;
  text-decoration: 'none';
  cursor: pointer;
  font-size: 15px;
}
@media (max-width: 1000px) {
  .icon-text {
    font-size: 0px;
  }
}
.style-login{
  text-decoration: none;
  font-weight: bold;
}
.dropdown-menu {
  /* background-color: rgba(255, 255, 255, 0.5); */
  border: none;
  /* Màu nền mờ */
}
