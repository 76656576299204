/* .nav-container {
    overflow-x: auto;
} */
.header-color {
    background-color: #FF6666!important;
}
.title{
    color: #fafafa;
    white-space: nowrap;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1000;
   
}

/*  */