.dash-widget-icon {
    align-items: center;
    display: inline-flex;
    font-size: 1.875rem;
    height: 50px;
    justify-content: center;
    line-height: 48px;
    text-align: center;
    width: 50px;
	border: 3px solid;
    border-radius: 50px;
    padding: 28px;
}
.avatar-img{
    height: 50px;
    width: 50px;
    object-fit: cover;
    margin-right: 10px;

}
.table-avatar a{
    /* font-size: 14px; */
    text-decoration: none;
    color: inherit;
}
.dash-count {
    font-size: 18px;
    margin-left: auto;
}
.dash-widget-info h3 {
    margin-bottom: 10px;
}
.dash-widget-header {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
}
.card-chart .card-body {
	padding: 8px;
}
#morrisArea > svg, #morrisLine > svg  {
	width: 100%;
}
.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 19px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}
.activity-feed .feed-item:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #177dff;
}
.activity-feed .feed-item .feed-date {
	display: block;
	position: relative;
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
 }
.activity-feed .feed-item .feed-text {
	color: #777;
	position: relative;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}
td.text-center {
    text-align: center;
}
td.centered {
    /* text-align: center; */
    vertical-align: middle;
}
td.centered {
    /* text-align: center; */
    vertical-align: middle;
    max-width: 200px; /* Đặt chiều rộng tối đa của ô */
    overflow: hidden;
    text-overflow: ellipsis; /* Hiển thị dấu chấm (...) khi nội dung bị tràn */
    white-space: nowrap; /* Ngăn ngừa việc tràn dòng */
    word-wrap: break-word; /* Cho phép nội dung xuống dòng */
}

.tag-button{
    background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
    border: none;
    font-weight: bold;
}
.tag-import{
    border: 1px solid #177dff;
    background-color: white;
    color: #177dff;
    font-weight: bold;

}
.product-img{
    height: 120px;
    width: 120px;
    object-fit: cover;
    margin-right: 10px;

}
.product-name {
    font-size: 14px;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}
.tag-button{
    background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
    border: none;
    font-weight: bold;
}
.tag-check {
    background: linear-gradient(310deg, rgb(23, 173, 55), rgb(152, 236, 45));
    color: white;
    font-weight: bold;
    font-size: 20px;
}
.tag-delete {
    background-image: linear-gradient(310deg, rgb(98, 117, 148), rgb(168, 184, 216));
    color: white;
    font-weight: bold;
    font-size: 20px;
}
.info-title {
    font-size: 15px;
    font-weight: bold;
    color: #777;
    margin-right: 10px;
}
.info-container {
    display: flex;
    align-items: center;
}

#fileInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; /* Ẩn input thật đi */
}

