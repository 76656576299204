.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #e7dfdd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img {
    width: 250px;
    height: 250px;
    object-fit: cover;

}