.page-header {
    margin-bottom: 1.875rem;
}

.page-title {
    color: #333;
    margin-bottom: 5px;
}

.main-wrapper {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    /* background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
    height: calc(60vh - 10px); */
}

.page-wrapper {
    margin-left: 240px;
    padding-top: 60px;
    position: relative;
    transition: all 0.4s ease;
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
    height: calc(80vh - 60px);
    
}

.page-wrapper>.content {
    padding: 1.875rem 1.875rem 0;
}

.page-header {
    margin-bottom: 1.875rem;
}

.page-title {
    color: #333;
    margin-bottom: 5px;
}