.search-container {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 650px;
    height: 50px;
    padding:15px;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid #070707;

}

input[type="text"] {
    width: 100%;
    height: 100%;
    padding: 8px;
    border: none;
    font-size: 16px;
    color: #333;
    outline: none;
    background:transparent;
}

.search-icon {
    position: absolute;
    /* right: 0;
    font-size: 20px;
    color: #7B66FF;
    cursor: pointer; */
    right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
}
.search-container {
    position: relative;
  }
  
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff!important;
    border: 1px solid #ccc;
    border-top: none;
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 15px;
    
  }
  
  .suggestions li {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .suggestions li:hover {
    background-color: #f0f0f0;
  }
  